import WizardConfig, { PrefixEntry } from "@pldn/ldwizard/types/WizardConfig";
// @ts-ignore
import img from "./logo-clariah.svg";
// @ts-ignore
import favIcon from "./CLARIAH_icon.png";

const wizardConfig: WizardConfig = {
  appName: "Clariah Wizard",
  primaryColor: "#468dcb",
  secondaryColor: "#f26c50",
  dataplatformLink: "https://druid.datalegend.net",
  favIcon: favIcon,
  icon: img,
  predicateConfig: {
    method: "elastic",
    endpoint:
      "https://api.druid.datalegend.net/datasets/VocabularyRecommender/RecommendedVocabularies/services/RecommendedVocabularies/search",
  },
  classConfig: {
    method: "elastic",
    endpoint:
      "https://api.druid.datalegend.net/datasets/VocabularyRecommender/RecommendedVocabularies/services/RecommendedVocabularies/search",
  },
  getAllowedPrefixes: async () => {
    const response = await fetch(
      "https://api.druid.datalegend.net/datasets/VocabularyRecommender/RecommendedVocabularies/prefixes"
    );
    if (response.ok) {
      const prefixes: PrefixEntry[] = await response.json();
      return prefixes;
    }
    return [];
  },
};
export default globalThis.wizardConfig = wizardConfig;
